#magic-bar-wrapper[data-v-2dcf5e6f]{
  display:block;
  color:inherit
}
#magicbar-content-container[data-v-2dcf5e6f]{
  opacity:1;
  margin:0px;
  padding:0px;
  left:0px;
  height:58px;
  width:100%;
  z-index:100000;
  top:0px
}
#magicbar[data-v-2dcf5e6f]{
  text-align:center;
  margin:0px;
  padding:14px 10px;
  left:0px;
  height:auto;
  width:100%;
  box-sizing:border-box;
  border:none;
  position:absolute;
  background-color:black;
  color:#fff;
  font-size:18px;
  line-height:30px
}
